<template>
  <v-container>
    <h1 class="text-center">Custom Commands</h1>
    <div class="custom-container2">
      <p class="text-center"><b>Hier stehen die Verfügbaren Varriablen für die
        Custom Commands!</b></p>
      <v-row>
        <v-col cols="2">
          <p class = text-center><span style="color: #00ffcd;">[display-name]</span> wird verwendet
            um den Command ausführenden zu makieren.</p>
        </v-col>
        <v-col cols="2">
          <p class = text-center><span style="color: #00ffcd;">[toUser]</span> wird verwendet
            um den Namen des erwähnten Viewers im Command zu benutzen.</p>
        </v-col>
        <v-col cols="2">
          <p class = text-center><span style="color: #00ffcd;">[count]</span> wird verwendet
          um die Häufigkeit, eines Ereignisses zu zählen.</p>
        </v-col>
        <v-col cols="2">
          <p class = text-center><span style="color: #00ffcd;">[sub_count]</span> wird verwendet
            um die aktuelle Anzahl an Subs auszugeben.</p>
        </v-col>
        <v-col cols="2">
          <p class = text-center><span style="color: #00ffcd;">[sub_points]</span> wird verwendet
            um die aktuelle Anzahl an Abo Punkten auszugeben.</p>
        </v-col>
        <v-col cols="2">
          <p class = text-center><span style="color: #00ffcd;">[follower]</span> wird verwendet
            um die aktuelle Anzahl an Followern auszugeben.</p>
        </v-col>
      </v-row>
    </div>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <!-- <pre>
      {{ editedItem }}
    </pre> -->
    <v-data-table
      :search="search"
      :headers="headers"
      :items="commands"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:[`item.userCooldown`]="{ item }">
        {{ getUserCooldownText(item.cooldowntyp, item.userCooldown) }}
      </template>
      <template v-slot:[`item.cooldown`]="{ item }">
        {{ getGlobalCooldownText(item.cooldowntyp, item.cooldown) }}
      </template>
      <template v-slot:top>
        <v-dialog
          persistent
          v-model="dialog"
          max-width="80vw"
        >
          <v-card>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.cmd"
                      label="Command"
                    ></v-text-field>
                  </v-col>

                    <v-col
                    cols="6"
                    >
                    <v-select
                    v-model="editedItem.perm"
                    :items="Rang"
                    label="Permission"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-checkbox
                      v-model="editedItem.cooldowntyp"
                      label="Global Cooldown"
                      :disabled="editedItem.cooldowntyp === 2"
                      :true-value="1"
                      :false-value="3"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-text-field
                      v-model="editedItem.cooldown"
                      label="Globale Cooldown in s"
                      :disabled="editedItem.cooldowntyp === 2"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-checkbox
                      v-model="editedItem.cooldowntyp"
                      label="User Cooldown"
                      :disabled="editedItem.cooldowntyp === 1"
                      :true-value="2"
                      :false-value="3"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-text-field
                      v-model="editedItem.userCooldown"
                      label="User Cooldown in s"
                      :disabled="editedItem.cooldowntyp === 1"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editedItem.cmd_content"
                      label="cmd_content"
                      rows="1"
                      auto-grow
                      counter="2000"
                      maxLength="2000"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editedItem.description"
                      label="Description"
                      rows="1"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    class="chipGroup"
                  >
                    <v-label>Alias</v-label>
                    <v-chip-group
                      multiple
                    >
                      <v-chip
                        v-for="tag in editedItem.alias"
                        :key="tag"
                        close
                        close-icon="mdi-delete"
                        @click="editAlias(tag)"
                        @click:close="removeAlias(tag)"
                      >
                        <v-text-field
                          v-if="editedAlias === tag"
                          v-model="editedAliasValue"
                          @keydown="checkEditAliasKey"
                          @change="saveEditAlias"
                        ></v-text-field>
                        <span v-else>
                        {{ tag }}
                        </span>
                      </v-chip>
                      <v-btn
                        fab
                        small
                        dark
                        @click="newAlias"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :loading="saving"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Sicher diesen Command löschen?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Nein!</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja!</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          :disabled="$store.state.user_perm < 4 || $store.state.user_perm < item.user_perm"
        >
          mdi-pencil
        </v-icon>
        <v-icon
        small
        @click="deleteItem(item)"
        :disabled="$store.state.user_perm < 4 || $store.state.user_perm < item.user_perm"
      >
        mdi-delete
      </v-icon>
        <v-switch
          v-model="item.active"
          color="success"
          :true-value="1"
          :false-value="0"
          hide-details
          @change="saveSwitchState(item)"
          :disabled="$store.state.user_perm < 4 || $store.state.user_perm < item.user_perm"
        ></v-switch>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="dialog"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="command"
      :counter="32"
      :rules="[v => !!v || 'Item is required']"
      label="Command"
      required
    ></v-text-field>

    <v-select
      v-model="perm"
      :items="Rang"
      :rules="[v => !!v || 'Item is required']"
      label="Permission"
      required
    ></v-select>

    <v-text-field
      v-model="command_content"
      :rules="[v => !!v || 'Item is required']"
      label="Command Inhalt"
      required
    ></v-text-field>

    <v-text-field
      v-model="description"
      label="Description"
    ></v-text-field>

    <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      Command erstellen
    </v-btn>

  </v-form>
  <v-snackbar
      v-model="commandCreated"
      timeout="3000"
      color="green"
    >
      Command erfolgreich erstellt!

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="commandCreated = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'TTCustomCommands',
  data: () => ({
    description: '',
    search: '',
    dialog: false,
    commands: [],
    saving: false,
    Rang: ['Everyone', 'Sub', 'Vip', 'Mod', 'Editor', 'Admin', 'Streamer'],
    headers: [
      { text: 'Command', value: 'cmd', align: 'center' },
      { text: 'Alias', value: 'alias', align: 'center' },
      { text: 'Permission', value: 'perm', align: 'center' },
      { text: 'User Cooldown in s', value: 'userCooldown', align: 'center' },
      { text: 'Global Cooldown in s', value: 'cooldown', align: 'center' },
      { text: 'Command Inhalt', value: 'cmd_content', align: 'center' },
      { text: 'Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
    },
    defaultItem: {
    },
    editedAlias: null,
    editedAliasValue: '',
  }),
  mounted() {
    BackendService.loadCustomCommand(2).then((data) => {
      this.commands = data.data;
    });
  },
  methods: {
    getUserCooldownText(cooldownType, userCooldown) {
      switch (cooldownType) {
        case 2:
          return userCooldown;
        default:
          return '-';
      }
    },
    getGlobalCooldownText(cooldownType, globalCooldown) {
      switch (cooldownType) {
        case 1:
          return globalCooldown;
        default:
          return '-';
      }
    },
    newAlias() {
      this.editedItem.alias.push('');
      this.editAlias('');
    },
    editItem(item) {
      this.editedIndex = this.commands.indexOf(item);
      this.editedItem = { ...item };
      this.editedItem.alias = item.alias.slice();
      this.editedItem.active = item.active;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.commands.indexOf(item);
      this.editedItem = { ...item };
      this.editedItem.alias = item.alias.slice();
      this.dialogDelete = true;
    },
    removeAlias(alias) {
      this.editedItem.alias = this.editedItem.alias.filter((item) => item !== alias);
    },
    editAlias(alias) {
      this.editedAlias = alias;
      this.editedAliasValue = alias;
    },
    saveEditAlias() {
      const idx = this.editedItem.alias.indexOf(this.editedAlias);
      this.editedItem.alias[idx] = this.editedAliasValue;
      this.editedAlias = null;
    },
    checkEditAliasKey(e) {
      if (e.keyCode === 13) {
        this.saveEditAlias();
      } else if (e.keyCode === 27) {
        this.editedAlias = null;
      }
    },
    deleteItemConfirm() {
      BackendService.deleteCustomCommand({
        id: this.editedItem.id,
        variant: 2,
      }).then((data) => {
        this.commands = data.data;
        this.closeDelete();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        this.editedAlias = null;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        this.editedAlias = null;
      });
    },

    save() {
      this.saving = true;
      BackendService.saveCustomCommand({
        id: this.editedItem.id,
        cmd: this.editedItem.cmd,
        perm: this.editedItem.perm,
        alias: this.editedItem.alias,
        cmd_content: this.editedItem.cmd_content,
        description: this.editedItem.description,
        cooldown: this.editedItem.cooldown,
        userCooldown: this.editedItem.userCooldown,
        cooldowntyp: this.editedItem.cooldowntyp,
        variant: 2,
      }).then((data) => {
        this.commands = data.data;
        this.saving = false;
        this.close();
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        // console.log(this.command);
        // console.log(this.perm);
        // console.log(this.command_content);
        // console.log(this.description);
        BackendService.insertCustomCommand({
          cmd: this.command,
          perm: this.perm,
          cmd_content: this.command_content,
          description: this.description,
          variant: 2,
        }).then((data) => {
          this.commandCreated = true;
          this.$refs.form.reset();
          this.commands = data.data;
        });
      }
    },
    saveSwitchState(item) {
      if (item.active) {
        // console.log(item.id);
        // console.log('AN!');
        this.saveSwitchStateDB(item.id, 1);
      } else {
        // console.log(item.id);
        // console.log('AUS!');
        this.saveSwitchStateDB(item.id, 0);
      }
    },
    saveSwitchStateDB(id, state) {
      // console.log('SAVE');
      // console.log(id);
      // console.log(state);
      BackendService.saveSwitchStateCCommands({
        active: state,
        id1: id,
        variant: 2,
      }).then((data) => {
        this.commands = data.data;
        // console.log(this.commands);
      }).catch(() => {
        alert('nö!');
      });
    },
  },
};
</script>
<style>
.chipGroup label {
  font-size: 12px;
}

.custom-container2 {
  border: 1px solid #565656;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 5px;
  margin: 20px auto;
}

.custom-container3 {
  border: 1px solid #565656;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 5px;
  max-width: 900px;
  margin: 20px auto;
}

.custom-container4 {
  border: 1px solid #565656;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 5px;
  width: 100%;
  margin: 20px auto;
}

</style>
