<template>
  <v-container>
    <h1 class="text-center">Bot Erstellung</h1>
    <div v-if="currentStep === -1">Lade...</div>
    <div v-else-if="currentStep === 0">
      <p>Es freut uns sehr das du Dich für den ThoFu_Bot entschieden hast!</p>
      <p>Hiermit leiten wir Dich Schritt für Schritt durch den kleinen technischen,
        aber notwendigen Teil.</p>
      <h2 class="mt-7">Schritt 1 - Twitch Verknüpfung</h2>
      <p >auf dem folgenden Link: <a href='https://dev.twitch.tv/console/apps/create' target="_blank">Twitch</a></p>
      <p>Bitte einmal authorisieren/Authorize! Danach gelangst du dann in den Twitch
        Developer Bereich.</p>
      <v-img
        class="text-center"
        max-width="700"
        src="/Konsole-Twitch-Dev-bearb.png"
      ></v-img>
      <p>1. Unter Name gibt du dann der Anwendung einen beliebigen Namen!
        (der Bot heißt dennoch ThoFu_Bot weiterhin)
      </p>
      <p>2. Unter OAuth Redirect Urls fügt du diesen Link ein:
        <span style="color: red;">
          {{protocolAndDomain}}/twitch_auth/{{$route.params.id}} </span></p>
      <p>3. Unter Kategorie bitte "Chat Bot" einstellen.</p>
      <p>4. Client-Typ bleibt bitte auf Vertraulich!</p>
      <p>5. (Optional) Bestätigen das du ein Mensch bist</p>
      <p>6. Klick dann auf Erstellen.</p>
      <p>Damit gelangst du hierhin.</p>
      <v-img
        class="text-center"
        max-width="1000"
        src="/Konsole-Twitch-Dev2-bearb.png"
      ></v-img>
      <p>7. Auf Verwalten klicken. Dann siehst du diese Seite</p>
      <v-img
        class="text-center"
        max-width="700"
        src="/Konsole-Twitch-Dev3-bearb.png"
      ></v-img>
      <p>8. (Optional) Bestätigen das du ein Mensch bist.</p>
      <p>9. Die Client-ID kopierst du Dir bitte und trägst die unter Client-ID ein,
        auf der Seite der Boterstellung.</p>
      <p>10. Unter Neues Geheimnis, kannst du dir dein Client-Geheimnis anzeigen lassen</p>
      <p>11. Das Client-Geheimnis bitte kopieren und unter Client-Geheimnis,
        auf der Boterstellung einfügen.</p>
      <p>Nach dem alle Daten eingetragen sind auf den Button Weiter klicken!</p>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Client-ID"
            v-model="twitchClientId">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Client-Geheimnis"
            v-model="twitchClientSecret">
          </v-text-field>
        </v-col>
        <v-col cols="12" v-if="hasErrors">
          <v-alert
            dense
            outlined
            type="error"
          >
            Das hat leider nicht geklappt - bitte prüfe deine Daten und versuche es nochmal!
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="saveValues" :loading="loading">Weiter</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="currentStep === 1">
      <h2>Schritt 1.1. - Twitch Verknüpfung</h2>
      <p v-if="this.$route.query.code && !hasErrors">Validiere - bitte warten!</p>
      <div v-else>
        <p>Jetzt bräuchte deine erstelle App noch die Zustimmung (Authorisierung)
          von Twitch, was du mit dem folgenden Link machen kannst.</p>
        <p><a :href="twitchlink">Twitch Verbinden</a></p>
        <p>Falls du aber ein Fehler gemacht haben solltest, dann
          bekommst du auch eine Fehlermeldung und wir bitten Dich, den Twitch Daten zurücksetzen
          Button zu klicken und alles ab Schritt (9.) nochmal zu machen.</p>
          <v-alert
            v-if="hasErrors"
            dense
            outlined
            type="error"
          >
            Das hat leider nicht geklappt - bitte prüfe deine Daten und versuche es nochmal!
          </v-alert>
        <v-btn color="error" @click="resetTwitchData">Twitch Daten zurücksetzen</v-btn>
      </div>
    </div>
    <div v-else-if="currentStep === 2">
      <h2>Schritt 2 - Spotify</h2>
      <p>Wenn du es bis hierhin geschafft, Applaus an Dich. Mit Spotify haben wir ja auch einige
        Funktionen, beim Thofu_Bot. Daher brauchen wir hier auch einige Sachen (eigene App).</p>
      <p>1. Klicke bitte diesen Link an: <a href='https://developer.spotify.com/dashboard/create' target="_blank">Spotify</a></p>
      <p>Bitte die AGB's akzeptieren von Spotify.</p>
      <v-img
        class="text-center"
        max-width="1000"
        src="/Spotify-Create-App-bearb.png"
      ></v-img>
      <p>2. Nun vergibst du einen beliebigen Namen oder wie im Beispiel: Thofu_Bot</p>
      <p>3. Unter App description ist es egal was du einträgst z.B. SR für Songrequest.</p>
      <p>4. Website bleibt bitte frei!</p>
      <p>5. Hier trägst du die Redirect URL ein, die du auf der Boterstellungsseite findest:
      <span style="color: red;">
          {{protocolAndDomain}}/neues_abo/{{$route.params.id}}/spotify </span></p>
      <p>Unter Which API/SDKs kein Haken setzen!</p>
      <p>6. Die Terms of Services anhaken</p>
      <p>7. Save klicken.</p>
      <p>8. Nun siehst du ein neues Fenster wo du bitte rechts auf Settings klickst (Siehe Bild)</p>
      <v-img
        class="text-center"
        max-width="1000"
        src="/Spotify-Create-App2-bearb.png"
      ></v-img>
      <p>9. Im neuen Fenster bitte wir Dich unten erstmal auf View client secret zu klicken,
        damit das nächste Beispielbild so ausschaut, wie es ausschaut xD</p>
      <v-img
        class="text-center"
        max-width="1000"
        src="/Spotify-Create-App3-bearb.png"
      ></v-img>
      <p>10. Bitte kopiere nun Client ID und trage sie auf der Boterstellungsseite
        unter Spotify Client ID ein.</p>
      <p>11. Kopiere nun noch die Client Secret und trage diese auf der Boterstellungsseite
        unter Spotify Client Secret ein.</p>
      <p><b>NOCH NICHT WEITER KLICKEN!!!</b></p>
      <p>12. Den Tab User Management öffnen</p>
      <v-img
        class="text-center"
        max-width="1000"
        src="/Spotify-Create-App4-bearb.png"
      ></v-img>
      <p>13. Trage hier bitte deinen vollen Namen ein.</p>
      <p>14. Hier trägst Du bitte deine E-mail-Adresse ein,
        die du mit deinem Spotify verlinkt hast.</p>
      <p>15. Klicke bitte nach dem eintragen auf Add User.</p>
      <p>16. Nun auf der Boterstellungsseite auf Weiter klicken.</p>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            :label="SpotifyCID"
            v-model="spotifyClientId">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="SpotifyCS"
            v-model="spotifyClientSecret">
          </v-text-field>
        </v-col>
        <v-col cols="12" v-if="hasErrors">
          <v-alert
            dense
            outlined
            type="error"
          >
            Das hat leider nicht geklappt - bitte prüfe deine Daten und versuche es nochmal!
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="saveValues" :loading="loading">Weiter</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="currentStep === 3">
      <h2>Schritt 2.1. - Spotify Verknüpfung</h2>
      <p v-if="this.$route.query.code && !hasErrors">Validiere - bitte warten!</p>
      <div v-else>
        <p>Jetzt bräuchte deine erstelle App noch die Zustimmung (Authorisierung) von Spotify,
          was du mit dem folgenden Link machen kannst:</p>
        <p><a :href="spotifylink">Spotify Verbinden</a></p>
        <v-alert
          v-if="hasErrors"
          dense
          outlined
          type="error"
        >
          Das hat leider nicht geklappt - bitte prüfe deine Daten und versuche es nochmal!
        </v-alert>
        <v-btn color="error" @click="resetSpotifyData">Spotify Daten zurücksetzen</v-btn>
      </div>
    </div>
    <div v-else-if="currentStep === 4">
      <h2>Schritt 2.2 - Spotify Verknüpfung</h2>
      <p>Damit ThoFu_Bot dein Spotify auch erkennt, brauch es noch dein verwendetes Gerät
        (üblich ist da die Desktop App von Spotify)
        bitte lasse Musik auf Spotify laufen, auf dem Endgerät was du im Stream benutzt.</p>
      <p>1. Klicke erst dann, auf Gerät suchen</p>
      <v-row v-for="device in spotifyDevices" v-bind:key="device.id">
        <v-col cols="12">
          <v-btn color="primary" @click="useSpotifyDevice(device.id)">Dieses Gerät benutzen</v-btn>
          {{device.name}} (Typ: {{device.type}})
        </v-col>
      </v-row>
      <br />
      <v-btn color="primary" @click="checkSpotifyDevice" :loading="loading">Gerät Suchen</v-btn>
      <br />
      <br />
      <br />
      <p>2. Wähle bitte nun das Endgerät aus,
        wo grade Musik drauf läuft in dem du auf dem Button davor klickst.</p>
    </div>
    <div v-else-if="currentStep === 5">
      <h2>Fertig!</h2>
      <p>Du hast es geschafft!
        Dein Bot wird nun in wenigen Minuten eingerichtet,
        halte dafür bitte dein E-mail-Postfach im Auge.
        Du bekommst eine Bestätigungs-Mail wenn alles Ready ist.</p>
    <h2>Damit wünschen wir Dir viel Spaß mit dem Tool und hebe deinen Stream
      auf das nächste Level.</h2>
    </div>
    <v-snackbar
      v-model="ProfilSettingsSaved"
      timeout="3000"
      color="green"
    >
      Neuer Bot erstellt!
    </v-snackbar>
  </v-container>
</template>

<style>
.value-label {
  margin-bottom: 8px;
}

.saved-values-card {
  width: 80vw;
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  padding: 16px;
}

.saved-value-label {
  margin-bottom: 8px;
}
</style>

<script>

import BackendService from '@/services/BackendService';
import TwitchService from '@/services/TwitchService';

export default {
  data() {
    return {
      spotifyDevices: [],
      hasErrors: false,
      currentStep: -1,
      twitchClientId: '',
      twitchClientSecret: '',
      ProfilSettingsSaved: false,
      loading: false,
      settings: [],
      textValue1: '',
      textValue2: '',
      spotifyClientId: '',
      spotifyClientSecret: '',
      textValue5: '',
      textValue6: '',
      textValue7: '',
      SpotifyCID: 'Spotify Client ID',
      SpotifyCS: 'Spotify Client Secret',
      SpotifyDID: 'Spotify Device ID',
      SpotifyRF: 'Spotify Refreshtoken',
      Land: 'Land',
      showSavedValues: true,
      savedValues: {},
      tableHeaders: [
        { text: 'Eigenschaft', value: 'property' },
        { text: 'Wert', value: 'value' },
      ],
    };
  },
  created() {
    this.getCurrentStep();
  },
  computed: {
    activeSpotifyDevices() {
      return this.spotifyDevices.filter((item) => item.is_active);
    },
    twitchlink() {
      return TwitchService.getOauthLink(this.twitchClientId, `${this.protocolAndDomain}/twitch_auth/${this.$route.params.id}`, 'new_abo');
    },
    spotifylink() {
      return `https://accounts.spotify.com/authorize?client_id=${this.spotifyClientId}&response_type=code&redirect_uri=${this.protocolAndDomain}/neues_abo/${this.$route.params.id}/spotify&show_dialog=true&scope=playlist-read-private%20playlist-read-collaborative%20ugc-image-upload%20user-follow-read%20playlist-modify-private%20user-read-email%20user-read-private%20app-remote-control%20streaming%20user-follow-modify%20user-modify-playback-state%20user-library-read%20user-library-modify%20playlist-modify-public%20user-read-playback-state%20user-read-currently-playing%20user-read-recently-played%20user-read-playback-position%20user-top-read`;
    },
    protocolAndDomain() {
      return `${document.location.protocol}//${document.location.host}`;
    },
  },
  methods: {
    getCurrentStep() {
      this.hasErrors = false;
      BackendService.getSubscriptionStep(this.$route.params.id).then((data) => {
        this.currentStep = data.data;

        if (this.$route.name === 'new_subscription_twitchconnect') {
          BackendService.createSubscription({
            id: this.$route.params.id,
            code: this.$route.query.code,
            domain: this.protocolAndDomain,
          }).then((data2) => {
            if (data2.data.ok) {
              this.getCurrentStep();
              this.$router.push({ name: 'new_subscription', id: this.$route.params.id });
            } else {
              this.hasErrors = true;
            }
          });
        } else if (this.$route.name === 'new_subscription_spotifyconnect') {
          BackendService.createSubscription({
            id: this.$route.params.id,
            code: this.$route.query.code,
            domain: this.protocolAndDomain,
          }).then((data2) => {
            if (data2.data.ok) {
              this.getCurrentStep();
              this.$router.push({ name: 'new_subscription', id: this.$route.params.id });
            } else {
              this.hasErrors = true;
            }
          });
        }
      });
    },
    resetTwitchData() {
      BackendService.createSubscription({
        id: this.$route.params.id,
        resetTwitch: true,
      }).then((data) => {
        if (data.data.ok) {
          this.getCurrentStep();
        } else {
          this.hasErrors = true;
        }
      });
    },
    resetSpotifyData() {
      BackendService.createSubscription({
        id: this.$route.params.id,
        resetSpotify: true,
      }).then((data) => {
        if (data.data.ok) {
          this.getCurrentStep();
        } else {
          this.hasErrors = true;
        }
      });
    },
    useSpotifyDevice(id) {
      BackendService.createSubscription({
        id: this.$route.params.id,
        spotifyDevice: id,
      }).then((data) => {
        if (data.data.ok) {
          this.getCurrentStep();
        } else {
          this.hasErrors = true;
        }
      });
    },
    checkSpotifyDevice() {
      this.loading = true;
      BackendService.checkSpotifyDevice({
        id: this.$route.params.id,
      }).then((data) => {
        this.spotifyDevices = data.data;
        this.loading = false;
      });
    },
    saveValues() {
      this.hasErrors = false;
      switch (this.currentStep) {
        case 0:
          this.loading = true;
          BackendService.createSubscription({
            id: this.$route.params.id,
            twitchClientId: this.twitchClientId,
            twitchClientSecret: this.twitchClientSecret,
          }).then((data) => {
            if (data.data.ok) {
              this.getCurrentStep();
            } else {
              this.hasErrors = true;
            }
            this.loading = false;
          });
          break;
        case 2:
          this.loading = true;
          BackendService.createSubscription({
            id: this.$route.params.id,
            spotifyClientId: this.spotifyClientId,
            spotifyClientSecret: this.spotifyClientSecret,
          }).then((data) => {
            if (data.data.ok) {
              this.getCurrentStep();
            } else {
              this.hasErrors = true;
            }
            this.loading = false;
          });
          break;
        default:
          break;
      }
      if (false) {
        BackendService.createBot({
          twitchusername: this.textValue1,
          twitchrefreshtoken: this.textValue2,
          spotifycid: this.spotifyClientId,
          spotifycs: this.spotifyClientSecret,
        }).then(() => {
          this.ProfilSettingsSaved = true;
        }).catch(() => {
          alert('nö!');
        });
      }
    },
  },
};
</script>
