<template>
  <v-container>
    <v-row
      class="center-container"
      align="center"
      justify="center"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>

</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'TwitchAuthView',

  data: () => ({
    result: null,
    selectedOption: null,
    options: [],
    userid: 0,
    test: false,
    numbersOnly: '',
    visibleOptions: false,
  }),
  components: {},
  mounted() {
    if (this.$router.currentRoute.query.state !== undefined) {
      switch (this.$router.currentRoute.query.state) {
        case 'new_abo':
          this.$router.push(`/neues_abo/${this.$route.params.id}/twitch?code=${this.$router.currentRoute.query.code}`);
          break;
        case 'bot_state':
          this.$router.push(`/bot_state?code=${this.$router.currentRoute.query.code}`);
          break;
        default:
          console.log(`unknown state: ${this.$router.currentRoute.query.state}`);
          break;
      }
    }
    console.log(this.$router.currentRoute.query);
    // console.log(this.$store.state);
    // console.log(this.$store.state.login_from);
    /*
    BackendService.pullOptions(this.$router.currentRoute.query.code).then((data) => {
      // console.log(data.data);
      // console.log(this.$router.currentRoute.query.state);
      this.options = data.data.options;
      this.userid = data.data.userid;
      this.$store.commit('set_username', data.data.username);
      this.$store.commit('set_avatar', data.data.avatar);
      this.$store.commit('set_user_id', data.data.userid);
      const numberOfProperties = Object.keys(data.data.options).length;
      // console.log(`Anzahl der Eigenschaften im Objekt: ${numberOfProperties}`);
      // console.log(Object.values(data.data.options)[0]);
      if (this.$router.currentRoute.query.state !== undefined) {
        console.log('login public möglich!');
      }
      if (this.$store.state.db !== undefined) {
        const regex = /\d+/g;
        const numbers = this.$store.state.db.match(regex);
        if (numbers) {
          this.numbersOnly = numbers.join('');
          this.start(this.numbersOnly);
        }
      } else if (numberOfProperties === 1) {
        this.start(Object.values(data.data.options)[0]);
      } else {
        this.visibleOptions = true;
      }
    });
    */
  },
  methods: {
    handleOption(selectedName) {
      if (selectedName !== null) {
        // this.$store.commit('set_instanz', selectedName);
        const selectedId = this.options[selectedName];
        // Jetzt hast du die ausgewählte ID (selectedId)
        // console.log(`Ausgewählter Name: ${selectedName}`);
        // console.log(`Ausgewählte ID: ${selectedId}`);
        this.start(selectedId, selectedName);
      }
    },
    start(hostId, instanz) {
      BackendService.checkStatus2(hostId, this.userid, instanz).then((data2) => {
        // console.log('CHECK STATUS2');
        // console.log(data2.data);
        this.$store.commit('set_loggedin', data2.data.loggedIn);
        this.$store.commit('set_permname', data2.data.permname);
        this.$store.commit('set_user_perm', data2.data.user_perm);
        this.$store.commit('set_db', data2.data.db);
        this.$store.commit('set_loyaltypoints', data2.data.loyaltypoints);
        this.$store.commit('set_instanz', data2.data.instanz);
        this.$store.commit('set_hostId', data2.data.hostId);
        // console.log(data2);
        // console.log(this.$store.state.logged_in);
        // console.log(this.$store.state.db);
        // console.log(this.$router.currentRoute.query.state);
        // console.log('Locale Storage');
        // console.log(JSON.parse(localStorage.getItem('login_from')));
        const loginFrom = JSON.parse(localStorage.getItem('login_from'));
        console.log(loginFrom);
        if (loginFrom === 'giveaway') {
          this.$router.push(`/giveaway/${this.$router.currentRoute.query.state}`);
        } else if (loginFrom === 'rewards') {
          this.$router.push(`/loyality_rewards/${this.$router.currentRoute.query.state}`);
        } else {
          this.$router.push('/');
        }
      });
    },
  },
};
</script>
<style>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.custom-container {
  border: 1px solid #000;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}
.custom-margin {
  margin-top: 70px;
}
.custom-margin2 {
  margin-top: 30px;
}
.new-line {
  display: block;
  margin-top: 10px; /* Dieser Abstand kann nach Bedarf angepasst werden */
}
</style>
