<template>
  <v-container>
    <h1>Bot Status</h1>
    <v-row v-if="view === 'initial'">
      <v-col>
        <span style="color: inherit;">
          Twitch Token Status:
        </span>
        <span :class="{ 'text-green': refreshStateLoaded && refreshState, 'text-red':  refreshStateLoaded && !refreshState }">
          {{ refreshStateLoaded ? (refreshState ? 'Gültig' : 'Ungültig') : "Lade..." }}
        </span>
      </v-col>
        <v-col>
            <v-btn :href="twitchLink">neuer Refresh Token anfordern</v-btn>
        </v-col>
        <v-col v-if="false">
            {{ twitchLink }}
        </v-col>
        <v-col>
            <v-btn @click="requestRestart" :loading="restartRequested" class="restart_button">Bot neustarten</v-btn>
        </v-col>
    </v-row>
    <div v-else-if="view === 'refresh_token'">
        <v-row>
            <v-col>
                <v-btn @click="view = 'initial'">zurück</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                @TODO: Erklärtext: neuer return uri erfassen
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn>get new refresh token</v-btn>
            </v-col>
        </v-row>
    </div>
    <v-snackbar
      v-model="showBotRestartedSnackbar"
      timeout="3000"
      color="green"
      centered
      >
      Bot wurde erfolgreich neu gestartet

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="showBotRestartedSnackbar = false"
        >
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>

</v-container>
</template>
<script>
import BackendService from '@/services/BackendService';
import TwitchService from '@/services/TwitchService';

// import BackendService from '@/services/BackendService';

export default {
  name: 'BotState',
  data: () => ({
    client_id: null,
    hash: null,
    view: 'initial',
    restartRequested: false,
    refreshState: false,
    refreshStateLoaded: false,
    showBotRestartedSnackbar: false,
    timer: null,
  }),
  beforeDestroy() {
    if (this.timer !== null) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  },
  mounted() {
    if (this.timer !== null) {
      window.clearInterval(this.timer);
      this.timer = null;
    }

    BackendService.loadBotStateData().then((data) => {
      this.client_id = data.data.client_id;
      this.hash = data.data.hash;
      this.restartRequested = data.data.restartRequested;

      if (this.$route.query.code) {
        BackendService.updateRefreshToken({
          hash: this.hash,
          code: this.$route.query.code,
          domain: this.protocolAndDomain,
        }).then(() => {
          this.$router.push('/bot_state');
        });
      }
    });

    BackendService.checkRefreshToken().then((data) => {
      this.refreshStateLoaded = true;
      console.log(data);
      console.log(data.data.check);
      if (data.data.check === true) this.refreshState = true;
    });

    /*
    BackendService.loadCommandList(this.$router.currentRoute.params.id).then((data) => {
      this.commands = data.data;
    });
    */
  },
  computed: {
    twitchLink() {
      return TwitchService.getOauthLink(this.client_id, `${this.protocolAndDomain}/twitch_auth/${this.hash}`, 'bot_state');
      // return `https://id.twitch.tv/oauth2/authorize?client_id=${this.twitchClientId}&redirect_uri=${encodeURI(`${this.protocolAndDomain}/neues_abo/${this.$route.params.id}/twitch`)}&response_type=code&scope=channel%3Amanage%3Amoderators%20channel%3Amanage%3Avips%20bits%3Aread%20channel%3Aread%3Acharity%20channel%3Aread%3Apredictions%20channel%3Aread%3Avips%20moderation%3Aread%20moderator%3Amanage%3Achat_messages%20user%3Aread%3Asubscriptions%20channel%3Amanage%3Aredemptions%20channel%3Amanage%3Abroadcast%20moderator%3Amanage%3Abanned_users%20moderator%3Amanage%3Aannouncements%20channel%3Aread%3Aredemptions%20user%3Aread%3Aemail%20channel%3Amanage%3Apredictions%20channel%3Amanage%3Apolls%20channel%3Aread%3Ahype_train%20channel%3Aread%3Agoals%20channel%3Aread%3Aeditors%20moderator%3Aread%3Achatters%20moderator%3Aread%3Afollowers%20user%3Amanage%3Ablocked_users%20user%3Aread%3Ablocked_users%20user%3Amanage%3Awhispers%20channel%3Aread%3Asubscriptions`;
    },
    protocolAndDomain() {
      return `${document.location.protocol}//${document.location.host}`;
    },
  },
  methods: {
    loadBotStateData() {
      BackendService.loadBotStateData().then((data) => {
        this.restartRequested = data.data.restartRequested;
        if (this.timer !== null && !this.restartRequested) {
          this.showBotRestartedSnackbar = true;
          window.clearInterval(this.timer);
          this.timer = null;
        }
      });
    },
    requestRestart() {
      this.restartRequested = true;
      BackendService.restartBot().then(() => {
        BackendService.loadBotStateData().then((data) => {
          this.restartRequested = data.data.restartRequested;
          this.timer = window.setInterval(this.loadBotStateData, 5000);
        });
      });
    },
  },
};

</script>
<style>
.restart_button .v-btn__loader::after{
  content: 'Bot wird neu gestartet';
  display: block;
  margin-left: 10px;
}

.restart_button.v-btn--loading .v-btn__content {
  display: none;
}

.restart_button.v-btn--loading .v-btn__loader{
  position: relative;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

</style>
