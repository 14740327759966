<template>
  <v-app :class="{ transparent: $router.currentRoute.meta.transparentBackground }">
    <v-toolbar class="flex-grow-0">
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="py-4 text-center white--text thofu-menu"
          cols="12"
        >
          <v-btn :to="'/'">Home</v-btn>
          <v-btn :to="{name: 'pricing'}">Funktionen</v-btn>
          <v-btn :to="{name: 'about'}">Über uns</v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                v-bind="attrs"
                v-on="on"
              >
                Community
              </v-btn>
            </template>
            <v-list>
              <v-list-item href="https://discord.gg/8VHuXsrCQm" target="_blank">
                <v-list-item-title >Discord</v-list-item-title>
              </v-list-item>
              <v-list-item link :to="{name: 'faq'}">
                <v-list-item-title >FAQ</v-list-item-title>
              </v-list-item>
              <v-list-item link :to="{name: 'imprint'}">
                <v-list-item-title >impressum</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-btn
        tile
        color="#9147ff"
        class="text--white"
        :href="loginUrl"
        v-if="!$store.state.logged_in"
      >
        <v-icon left>
          mdi-twitch
        </v-icon>
        Login mit Twitch
      </v-btn>

    </v-toolbar>
    <v-layout v-if="$store.state.state_loaded">
      <v-navigation-drawer
        permanent
        expand-on-hover
        v-if="$store.state.logged_in && $store.state.user_perm > -2
          && $router.currentRoute.name !== 'editoverlay'"
        :mini-variant.sync="navigationDrawerMini"
      >
        <v-list>
          <v-list-item class="px-2" v-if="$store.state.avatar">
            <v-list-item-avatar>
              <v-img :src="$store.state.avatar"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6" @click="$router.push({ name: 'home' })">
                {{ $store.state.username }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="false">business@just-dean.de</v-list-item-subtitle>
              <v-list-item-subtitle v-if="$store.state.user_perm > 3">Twitch Rang: {{ $store.state.permname }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="$store.state.tiktok_user_perm > 3">TikTok Rang: {{ $store.state.tiktok_permname }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="$store.state.instanz !== null">
                ------------------------------</v-list-item-subtitle>
              <v-list-item-subtitle v-if="$store.state.instanz !== null">
                Instanz: {{$store.state.instanz}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-group
            :value="false"
            prepend-icon="mdi-account-circle"
            v-if="$store.state.user_perm >= 8"
          >
            <template v-slot:activator>
              <v-list-item-title>Administration</v-list-item-title>
            </template>
            <v-list-item link @click="$router.push({ name: 'newbot' })"
                         v-if="$store.state.user_perm >= 8"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Bot erstellen</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="false"
            prepend-icon="mdi-account-circle"
            v-if="$store.state.user_perm >= 4"
          >
            <template v-slot:activator>
              <v-list-item-title>Twitch-Bot</v-list-item-title>
            </template>
            <v-list-item link @click="$router.push({ name: 'profil' })"
                         v-if="$store.state.user_perm >= 7"
            >
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Profil</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'commands' })"
                         v-if="$store.state.user_perm >= 4"
            >
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Commands</v-list-item-title>
            </v-list-item>
            <v-list-item link  @click="$router.push({ name: 'ccomands' })"
                         v-if="$store.state.user_perm >= 4"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Custom Commands</v-list-item-title>
            </v-list-item>
            <v-list-item link  @click="$router.push({ name: 'interval_msg' })"
                         v-if="$store.state.user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-message</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Auto Nachrichten [NEU]</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'loyality' })"
                         v-if="$store.state.user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Loyality Settings</v-list-item-title>
            </v-list-item>
            <v-list-group
              :value="false"
              no-action
              sub-group
              v-if="$store.state.user_perm >= 6"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title >Giveaway</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link  @click="$router.push({ name: 'giveawaysettings' })"
                           v-if="$store.state.user_perm >= 6"
              >
                <v-list-item-icon>
                  <v-icon>mdi-ticket</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Loyality</v-list-item-title>
              </v-list-item>

              <v-list-item link  @click="$router.push({ name: 'giveawayNoPoints' })"
                           v-if="$store.state.user_perm >= 5"
              >
                <v-list-item-icon>
                  <v-icon>mdi-ticket</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Chat [NEU]</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item link  @click="$router.push({ name: 'ccs' })"
                         v-if="$store.state.user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'obs' })"
                         v-if="$store.state.user_perm >= 7"
            >
              <v-list-item-icon>
                <v-icon>mdi-broadcast</v-icon>
              </v-list-item-icon>
              <v-list-item-title>OBS Integration</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'subathon' })"
                         v-if="$store.state.user_perm >= 6"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Subathon Timer Settings</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'cp' })"
                         v-if="$store.state.user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Kanalpunkte</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'bot_state' })"
                         v-if="$store.state.user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-robot-dead</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Bot Status</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'loyality_rewards_settings' })"
                         v-if="$store.state.user_perm >= 5 && showLoyalityRewards"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Loyaltiy Rewards [WIP]</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'loyality_reward_list' })"
                         v-if="$store.state.user_perm >= 5 && showBelohnungsanfragen"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Belohnungsanfragen [WIP]</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'overlay' })"
                         v-if="$store.state.user_perm >= 8"
            >
              <v-list-item-icon>
                <v-icon>mdi-layers-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Overlays [WIP!]</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="false"
            prepend-icon="mdi-account-circle"
            v-if="$store.state.tiktok_user_perm >= 4"
          >
            <template v-slot:activator>
              <v-list-item-title>TikTok Bot</v-list-item-title>
            </template>
            <v-list-item link @click="$router.push({ name: 'ttcommands' })"
                         v-if="$store.state.tiktok_user_perm >= 4"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Commands</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'ttccommands' })"
                         v-if="$store.state.tiktok_user_perm >= 4"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Custom Commands</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'ttloyality' })"
                         v-if="$store.state.tiktok_user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Loyality Settings</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'ttcs' })"
                         v-if="$store.state.tiktok_user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push({ name: 'newbot' })"
                         v-if="$store.state.tiktok_user_perm >= 5"
            >
              <v-list-item-icon>
                <v-icon>mdi-twitch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>---------------</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <router-view v-if="publicOrLoggedin" />
        <v-container v-else>
          <img src="/img/thor.png" alt="Logo" class="logo">
          <center>
          <h1>Login</h1>
          <v-btn
            tile
            color="#9147ff"
            class="text--white"
            :href="loginUrl"
          >
            <v-icon left>
              mdi-twitch
            </v-icon>
            Login mit Twitch
          </v-btn>
          </center>
        </v-container>
      </v-main>
    </v-layout>
    <v-footer>
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="py-4 text-center white--text"
          cols="12"
        >
          <v-btn
            color="white"
            text
            rounded
            class="my-2"
            :to="{name: 'imprint'}"
          >
            Impressum
          </v-btn>
          <v-btn
            color="white"
            text
            rounded
            class="my-2"
            :to="{name: 'tos'}"
            href="/agb"
          >
            AGB
          </v-btn>
          © {{ new Date().getFullYear() }} — <strong>ThoFu_bot</strong>
        </v-col>
      </v-row>    </v-footer>
    <!-- <div>
      <input v-model="testInput"><br />
      TestInputL: {{testInput}}<br />
      x{{ loggedIn }}x
      <a href="#" @click="loggedIn = !loggedIn">xx</a>
    </div> -->
    <v-snackbar
      v-model="loginError"
      timeout="3000"
      color="red"
    >
      Login fehlgeschlagen

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="loginError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'App',

  data: () => ({
    testInput: '',
    showPassword: false,
    username: '',
    password: '',
    loginLoading: false,
    loginError: false,
    navigationDrawerMini: null,
  }),
  watch: {
    navigationDrawerMini(newValue) {
      this.$store.commit('set_navigation_drawer_mini', newValue);
    },
  },
  created() {
    // console.log('CREATE AUSGEFÜHRT!!!');
    // console.log(this.$store.state.logged_in);
    // console.log(this.$store.state.db);
    this.checkStatus(this.$store.state.logged_in, this.$store.state.db, this.$store.state.tiktok_db);
  },
  computed: {
    showLoyalityRewards() {
      return !!process.env.VUE_APP_SHOW_LOYALITY_REWARDS;
    },
    showBelohnungsanfragen() {
      return !!process.env.VUE_APP_SHOW_BELOHNUNGSANFRAGEN;
    },
    publicOrLoggedin() {
      return (this.$route.meta && this.$route.meta.isPublic === true)
        || this.$store.state.logged_in;
    },
    loginUrl() {
      // this.$store.commit('set_login_from', 'main');
      return `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_TWITCH_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_TWITCH_REDIRECT_URL}&scope=user%3Aread%3Aemail`;
    },
  },
  methods: {
    checkStatus(loggedIn, db, tiktokDb) {
      BackendService.checkStatus(loggedIn, db, tiktokDb).then((data) => {
        // console.log('STATUS ANTWORT!!!');
        // console.log(data);
        this.$store.commit('set_loggedin', data.data.loggedin);
        this.$store.commit('set_username', data.data.username);
        this.$store.commit('set_user_id', data.data.userid);
        this.$store.commit('set_avatar', data.data.avatar);
        this.$store.commit('set_permname', data.data.permname);
        this.$store.commit('set_user_perm', data.data.user_perm);
        this.$store.commit('set_loyaltypoints', data.data.loyaltypoints);
        this.$store.commit('set_stateloaded', true);
        this.$store.commit('set_db', data.data.db);
        this.$store.commit('set_instanz', data.data.instanz);
        this.$store.commit('set_hostId', data.data.hostUserId);
        this.$store.commit('set_tiktok_user_perm', data.data.tiktok_user_perm);
        this.$store.commit('set_tiktok_permname', data.data.tiktok_permname);
        this.$store.commit('set_tiktok_db', data.data.tiktok_db);
        this.$store.commit('set_tiktok_loyaltypoints', data.data.tiktok_loyaltypoints);
        this.$store.commit('set_tiktok_broadcasterId', data.data.tiktok_broadcasterId);
        // console.log(this.$store.state.logged_in);
        // console.log(this.$store.state.db);
      });
    },
    logout() {
      BackendService.logout().then((data) => {
        this.$store.commit('set_loggedin', data.data === 1);
        this.$store.commit('set_username', '');
        this.$store.commit('set_avatar', null);
        this.$store.commit('set_permname', null);
        this.$store.commit('set_user_perm', null);
        this.$store.commit('set_loyaltypoints', 0);
        this.$store.commit('set_ticket', 0);
        this.$store.commit('set_instanz', null);
        this.$store.commit('set_tiktok_user_perm', null);
        this.$store.commit('set_tiktok_db', null);
        this.$store.commit('set_tiktok_loyaltypoints', 0);
        this.$store.commit('set_tiktok_broadcasterId', null);
        localStorage.clear();
        if (this.$router.currentRoute.path !== '/') {
          this.$router.push('/');
        }
      });
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer {
  flex-shrink: 0;
}

.logo {
  max-width: 300px;
  margin: 0 auto;
  display: block;
  border-radius: 25%;
}

.theme--light.v-btn.text--white {
  color: white;
}

.v-main__wrap {
  padding-bottom: 25px;
}

.thofu-menu {
  .button, .v-btn {
    margin: 0 5px;
  }
}

</style>
