import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    username: '',
    userid: '',
    avatar: null,
    permname: null,
    user_perm: null,
    logged_in: false,
    loyaltypoints: 0,
    ticket_from_user: 0,
    state_loaded: false,
    db: null,
    instanz: null,
    navigation_drawer_mini: true,
    hostId: null,
    login_from: null,
    tiktok_user_perm: null,
    tiktok_permname: null,
    tiktok_db: null,
    tiktok_loyaltypoints: 0,
    tiktok_broadcasterId: null,
  },
  getters: {
  },
  mutations: {
    set_loyaltypoints(state, payload) {
      state.loyaltypoints = payload;
    },
    set_loggedin(state, payload) {
      state.logged_in = payload;
    },
    set_username(state, payload) {
      state.username = payload;
    },
    set_avatar(state, payload) {
      state.avatar = payload;
    },
    set_permname(state, payload) {
      state.permname = payload;
    },
    set_user_perm(state, payload) {
      state.user_perm = payload;
    },
    set_ticket(state, payload) {
      state.ticket_from_user = payload;
    },
    set_user_id(state, payload) {
      state.userid = payload;
      localStorage.setItem('userid', JSON.stringify(payload));
    },
    set_stateloaded(state, payload) {
      state.state_loaded = payload;
    },
    set_db(state, payload) {
      state.db = payload;
    },
    set_instanz(state, payload) {
      state.instanz = payload;
    },
    set_hostId(state, payload) {
      state.hostId = payload;
    },
    set_navigation_drawer_mini(state, payload) {
      state.navigation_drawer_mini = payload;
    },
    set_login_from(state, payload) {
      state.login_from = payload;
      localStorage.setItem('login_from', JSON.stringify(payload));
    },
    set_tiktok_user_perm(state, payload) {
      state.tiktok_user_perm = payload;
    },
    set_tiktok_permname(state, payload) {
      state.tiktok_permname = payload;
    },
    set_tiktok_db(state, payload) {
      state.tiktok_db = payload;
    },
    set_tiktok_loyaltypoints(state, payload) {
      state.tiktok_loyaltypoints = payload;
    },
    set_tiktok_broadcasterId(state, payload) {
      state.tiktok_broadcasterId = payload;
    },
  },
  actions: {
  },
  modules: {
  },
});
