<template>
  <v-container>
    <h1 class="text-center">Command Settings</h1>
    <v-toolbar
      flat
    > <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    </v-toolbar>
    <!-- <pre>
      {{ editedItem }}
    </pre> -->
    <v-data-table
      :search="search"
      :headers="headers"
      :items="commands"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-dialog
          persistent
          v-model="dialog"
          max-width="80vw"
        >
          <v-card>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.var_name"
                      label="Setting"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="editedItem.var_type === 2"
                  >
                    <v-text-field
                      v-model="editedItem.var_content"
                      label="Wert"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="editedItem.var_type === 3"
                  >
                    <v-text-field
                      v-model="editedItem.var_content"
                      label="Wert"
                      type="Number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="chipGroup"
                    v-if="editedItem.var_type === 1"
                  >
                    <v-label>Wert</v-label>
                    <v-chip-group
                      multiple
                    >
                      <v-chip
                        v-for="tag in editedItem.var_content"
                        :key="tag"
                        close
                        close-icon="mdi-delete"
                        @click="editAlias(tag)"
                        @click:close="removeAlias(tag)"
                      >
                        <v-text-field
                          v-if="editedAlias === tag"
                          v-model="editedAliasValue"
                          @keydown="checkEditAliasKey"
                          @change="saveEditAlias"
                        ></v-text-field>
                        <span v-else>
                        {{ tag }}
                        </span>
                      </v-chip>
                      <v-btn
                        fab
                        small
                        dark
                        @click="newAlias"
                        :disabled="enableBtn === false"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :loading="saving"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.var_name`]="{ item }">
        <div>
          {{ item.var_name === 'song_queue_length' ? 'Anzahl der Songs' : '' }}
          {{ item.var_name === 'srblacklist' ? 'SR-Blacklist' : '' }}
          {{ item.var_name === 'everyso' ? 'SO-Blacklist' : '' }}
          {{ item.var_name === 'raidvieweranz' ? 'Auto SO bei X Viewern' : '' }}
          {{ item.var_name === 'voteskipanz' ? 'Skip Song bei X stimmen' : '' }}
          {{ item.var_name === 'usercooldown_min' ? 'Usercooldown in Min' : '' }}
          {{ item.var_name === 'message_per_10sec' ?
          'Max. Befehle pro User pro 10 Sekunden' : '' }}
          {{ item.var_name === 'alert_tier3_subs_sec' ?
          'Stumme Musik beim Tier3 Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_5subs_sec' ?
          'Stumme Musik beim 5er Sub-Bomb Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_10subs_sec' ?
          'Stumme Musik beim 10er Sub-Bomb Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_20subs_sec' ?
          'Stumme Musik beim 20er Sub-Bomb Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_50subs_sec' ?
          'Stumme Musik beim 50er Sub-Bomb Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_75subs_sec' ?
          'Stumme Musik beim 75er Sub-Bomb Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_100subs_sec' ?
          'Stumme Musik beim 100er Sub-Bomb Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_500bits_sec' ?
          'Stumme Musik beim 500 Bit Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_1000bits_sec' ?
          'Stumme Musik beim 1000 Bit Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_2000bits_sec' ?
          'Stumme Musik beim 2000 Bit Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_5000bits_sec' ?
          'Stumme Musik beim 5000 Bit Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_10000bits_sec' ?
          'Stumme Musik beim 10000 Bit Alert für X Sekunden' : '' }}
          {{ item.var_name === 'firstlist_anz' ? 'Größe der TOP "Erster"' : '' }}
          {{ item.var_name === 'raidlist_anz' ? 'Größe der Liste der Top Raider' : '' }}
          {{ item.var_name === 'live_time_raider_min' ? 'Min. Raider Livetime' : '' }}
          {{ item.var_name === 'kategorie_time_sec' ? 'Dauer anzeige Kategorie in Sek.' : '' }}
          {{ item.var_name === 'points_anz' ?
          'Größe der Liste der Top Points' : '' }}
          {{ item.var_name === 'so_delay_sec' ?
          'Dauer der Verzögerung in sec des auto SO' : '' }}
          {{ item.var_name === 'sr_timeline_min' ? 'Wiederholte SR in min' : '' }}
          {{ item.var_name === 'daily_sub_goal' ? 'Tägliches Sub Goal' : '' }}
          {{ item.var_name === 'daily_bit_goal' ? 'Tägliches Bit Goal' : '' }}
          {{ item.var_name === 'alert_tier1_subs_sec' ?
          'Stumme Musik beim Tier1 Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_tier2_subs_sec' ?
          'Stumme Musik beim Tier2 Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_1bits_sec' ?
          'Stumme Musik beim 1 Bit Alert für X Sekunden' : '' }}
          {{ item.var_name === 'alert_100bits_sec' ?
          'Stumme Musik beim 100 Bit Alert für X Sekunden' : '' }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          :disabled="$store.state.user_perm < 6"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="dialog"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'CommandSettings',
  data: () => ({
    enableBtn: false,
    search: '',
    test: true,
    dialog: false,
    commands: [],
    saving: false,
    var_content_to_save: '',
    headers: [
      { text: 'Description', value: 'description', align: 'center' },
      { text: 'Wert', value: 'var_content', align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
    },
    defaultItem: {
    },
    editedAlias: null,
    editedAliasValue: '',
    blacklist: [
      'voteskipanz',
      'usercooldown_min',
    ],
  }),
  computed: {
  },
  mounted() {
    BackendService.loadCommandSettings({ variant: 2 }).then((data) => {
      this.commands = data.data;
    });
  },
  methods: {
    isWordInBlacklist(word) {
      const wordToCheck = word; // Das zu überprüfende Wort
      // console.log(word);
      // console.log(this.blacklist.includes(wordToCheck));
      return this.blacklist.includes(wordToCheck);
    },
    newAlias() {
      this.editedItem.var_content.push('');
      this.editAlias('');
    },
    editItem(item) {
      this.editedIndex = this.commands.indexOf(item);
      this.editedItem = { ...item };
      this.editedItem.var_content = item.var_content.slice();
      this.dialog = true;
      // console.log(this.editedItem.var_content);
      // console.log(this.editedItem.var_content.length > 0);
      this.enableBtn = !(this.isWordInBlacklist(this.editedItem.var_name)
        && this.editedItem.var_content.length > 0);
    },
    removeAlias(alias) {
      this.editedItem.var_content = this.editedItem.var_content.filter((item) => item !== alias);
    },
    editAlias(alias) {
      this.editedAlias = alias;
      this.editedAliasValue = alias;
    },
    saveEditAlias() {
      const idx = this.editedItem.var_content.indexOf(this.editedAlias);
      this.editedItem.var_content[idx] = this.editedAliasValue;
      this.editedAlias = null;
    },
    checkEditAliasKey(e) {
      if (e.keyCode === 13) {
        this.saveEditAlias();
      } else if (e.keyCode === 27) {
        this.editedAlias = null;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        this.editedAlias = null;
      });
    },

    save() {
      this.saving = true;
      BackendService.saveCommandSettings({
        id: this.editedItem.id,
        var_name: this.editedItem.var_name,
        var_content: this.editedItem.var_content,
        var_type: this.editedItem.var_type,
        description: this.editedItem.description,
        variant: 2,
      }).then((data) => {
        this.commands = data.data;
        this.saving = false;
        this.close();
      });
    },
  },
};
</script>
<style>
.chipGroup label {
  font-size: 12px;
}
</style>
